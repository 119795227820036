<template>
  <AppPageCard>
    <template #card-title> Inventory Category</template>
    <b-table
      responsive
      hover
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center"
    ></b-table>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "CompanyInventoryCategoryPage",
  components: { AppPageCard },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
        },
      ],
      items: [
        { index: 1, name: "IT", description: "Information Technology" },
        { index: 2, name: "Others", description: "Others" },
        { index: 3, name: "Logistic", description: "Logistic" },
      ],
    };
  },
};
</script>

<style scoped></style>
